import store from '@/store'
import { get, post, postStatus } from './axios'

const systemId = () => store.state.system.systemId

export default {
  // 实名认证--二要素校验
  twoElements(params) {
    return postStatus('/user/credit/twoElements', params)
  },
  // 实名认证--运营商三要素认证
  threeElements(params) {
    return post('/user/credit/threeElements', params)
  },
  // 实名认证--银行卡四要素认证
  fourElements(params) {
    return post('/user/credit/fourElements', params)
  },
  // 人脸第一步--获取人脸通道
  getFaceChannel(params) {
    return get('/user/credit/getFaceChannel', params)
  },
  // 实名认证--二要素上传
  idCardOcrVerify(params) {
    return post('/user/credit/idCardOcrVerify', params)
  },
  // 实名认证--视频上传
  livenessVerify(params) {
    return post('/user/credit/livenessVerify', params)
  },
  // 获取手机验证码
  getTelCode({
    telephone,
    businessId,
    type, // 0 注册 1 找回密码 2 实名认证 15 更换签署密码 16 验证码登录
  }) {
    return get('/user/passport/getTelCode', {
      telephone,
      businessId,
      type,
      systemId: systemId(),
    })
  },
  /**
   * 获取邮箱验证码
   */
  getEmailCode(param) {
    return get('/user/passport/getEmailCode', param)
  },
  // 修改签署密码
  updateSignPwd(param) {
    return get('/user/my/updateSignPwd', param)
  },
  /**
   * 校验验证码
   * @param {*} param
   */
  checkTelCode(param) {
    return get('/user/my/checkTelCode', param)
  },
}
