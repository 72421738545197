<template>
  <div class="input-value" :class="showKeyboard ? 'input-value2' : ''">
    <div class="text-content">
      <div class="telCode" v-if="desType === 1">
        已发送至手机尾号{{ telCode }}
      </div>
      <!-- <div> -->
      <template v-if="desType === 1">
        <div class="sendCode">
          <template>
            <div
              :class="pwdErrMsg ? 'getCode msgVis' : 'getCode'"
              @click.stop="sendCode(13)"
              v-if="!signPwdTimerFlag"
            >
              获取验证码
            </div>
            <div class="getCode2 msgVis" v-else>重新发送（{{ num }}）</div>
          </template>
        </div>
      </template>
      <div class="msgBox2" v-else-if="desType === 2 && !codeSuccessSign">
        <van-field
          v-model="code"
          @focus="onfocus"
          placeholder="请输入验证码"
          :formatter="value => value.replace(/[^\d]/g, '')"
          @input="inputCode"
          maxlength="6"
        >
          <template #button>
            <div
              class="send-code"
              v-if="!signPwdTimerFlag"
              @click.stop="sendCode(15)"
            >
              获取验证码
            </div>
            <div class="send-code2" v-else>重新发送（{{ num }}）</div>
          </template>
        </van-field>
        <div class="msg2">{{ pwdErrMsg }}</div>
      </div>
      <div v-else class="forgetPass">
        <span @click="goUpdataPWD">忘记密码?</span>
      </div>
      <!-- </div> -->
    </div>
    <van-password-input
      v-if="(desType === 2 && codeSuccessSign) || desType !== 2"
      :value="value"
      :mask="type === 'number' ? false : true"
      :focused="showKeyboard"
      @focus="showKeyboard = true"
    />
    <div class="sub">
      <div class="msg">{{ pwdErrMsg }}</div>
    </div>
    <van-number-keyboard
      :show="showKeyboard"
      :hide-on-click-outside="false"
      @input="onInput"
      @delete="onDelete"
      @blur="showKeyboard = false"
    />
  </div>
</template>
<script>
  import { PasswordInput, NumberKeyboard } from 'vant'
  import Api from '@/api'
  import { mapState, mapGetters } from 'vuex'

  export default {
    props: {
      type: {
        type: String,
        default: 'number',
      },
      desType: {
        type: Number,
        default: 4,
      },
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
        userInfo: state => state.user.userInfo,
        showSignPassword: state => state.contractSign.showSignPassword,
        pwdErrMsg: state => state.contractSign.pwdErrMsg,
      }),
    },
    watch: {
      pwdErrMsg(newValue) {
        if (newValue) {
          if (this.desType === 2) {
            this.code = ''
            return
          }
          this.value = ''
        }
      },
    },
    data() {
      return {
        value: '',
        showKeyboard: true,
        num: 60,
        signPwdTimerFlag: false,
        inputValue: '',
        code: '',
        codeSuccessSign: false, // 修改密码，验证码校验状态
        telCode: null,
      }
    },
    mounted() {
      this.telCode = this.userInfo.telephone.substring(
        this.userInfo.telephone.length - 4
      )
      if (this.type === 'number') {
        this.sendCode(13)
      }
    },
    methods: {
      goUpdataPWD() {
        this.$router.push({
          path: '/updatePWD',
        })
      },
      onfocus() {
        this.showKeyboard = false
      },
      inputCode(v) {
        this.$store.commit('contractSign/setErrMsg', null)
        if (this.code.length === 6) {
          Api.checkTelCode({
            telCode: this.code,
          })
            .then(res => {
              this.codeSuccessSign = true
              this.showKeyboard = true
            })
            .catch(err => {
              this.$store.commit('contractSign/setErrMsg', err.msg)
            })
        }
      },
      onInput(key) {
        this.$store.commit('contractSign/setErrMsg', null)
        this.value = (this.value + key).slice(0, 6)
        if (this.value.length === 6 && this.desType === 2) {
          this.showKeyboard = false
          this.$store.commit('contractSign/setErrMsg', null)
          this.$emit('inputComplete', this.value, this.code)
        }
        if (this.value.length === 6 && this.desType !== 2) {
          this.$emit('inputComplete', this.value)
        }
      },
      onDelete() {
        this.value = this.value.slice(0, this.value.length - 1)
      },
      // 验证输入的值是手机还是邮箱
      isMobile(value) {
        return /^\d+$/g.test(value)
      },
      isEmail(value) {
        return /[a-zA-Z@]/g.test(value)
      },
      sendCode(type) {
        console.log(120)
        const that = this
        that.signPwdTimerFlag = true
        this.signPwdTimer = setInterval(() => {
          that.num -= 1
          if (that.num <= 0) {
            clearInterval(that.signPwdTimer)
            that.signPwdTimerFlag = false
            that.num = 60
          }
        }, 1000)
        if (this.isMobile(this.userInfo.account)) {
          Api.getTelCode({
            telephone: this.userInfo.telephone,
            businessId: this.$route.query.businessId,
            type,
          })
        } else if (this.isEmail(this.userInfo.account)) {
          Api.getEmailCode({
            email: this.userInfo.account,
            type: type === 13 ? 5 : type,
            systemId: this.systemInfo.systemId,
          })
        }
      },
      reSetValue() {
        this.value = ''
        this.verifyCode = ''
        this.code = ''
        this.showKeyboard = true
        if (this.signPwdTimer) clearInterval(this.signPwdTimer)
        this.signPwdTimer = null
        this.signPwdTimerFlag = false
        this.num = 60
      },
    },
  }
</script>
<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .input-value .van-hairline--left::after {
    border-color: #e2e4ea;
  }
  .input-value [class*='van-hairline']::after {
    border-color: #e2e4ea;
  }
</style>
<style lang="less" scoped>
  .input-value {
    margin: 0 auto;
    width: 330px;
    height: auto;
    margin-bottom: 24px;
  }
  .input-value2 {
    height: 350px;
  }
  .text-content {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin: 0 16px 12px;
    .telCode {
      color: #8a9ab4;
    }
  }
  .sub {
    padding-top: 20px;
    width: 300px;
    margin: 0 auto;
    position: relative;
    .msg {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #fa5050;
      text-align: center;
      // position: absolute;
      // top: 20px;
      // left: 50%;
      // transform: translate(-50%, 0);
    }

    /deep/.van-cell {
      padding: 13px 16px !important;
      height: 46px;
      line-height: 18px;
      border: 1px solid #e2e4ea;
      border-radius: 2px;
      margin-bottom: 24px;
      .van-field__button {
        padding-left: 16px;
        border-left: 1px solid #dfdfdf;
      }
    }
  }
  .forgetPass {
    font-size: 14px;
    color: #677283;
    flex: 1;
    text-align: right;
  }
  .msgBox2 {
    .van-cell {
      margin-bottom: 14px !important;
    }
    .msg2 {
      width: 100%;
      font-size: 14px;
      line-height: 12px;
      color: #fa5050;
      text-align: center;
      z-index: 1;
    }
  }
  .sendCode {
    // margin: 0 auto;
    // width: 295px;
    // height: 50px;
  }
  .send-code {
    height: 18px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(22, 118, 255, 1);
    line-height: 18px;
  }
  .send-code2 {
    height: 18px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(109, 119, 144, 1);
    line-height: 18px;
  }
  .getCode {
    height: 12px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #1676ff;
    line-height: 16px;
  }
  .getCode2 {
    height: 12px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #8a9ab4;
    line-height: 16px;
  }
  .msgVis {
    text-align: right;
  }
</style>
