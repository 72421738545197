<template>
  <van-popup
    v-model="show"
    get-container="body"
    round
    :close-on-click-overlay="false"
    position="bottom"
    @closed="closed"
  >
    <div class="setPassWord">
      <template v-if="signWay === 0">
        <template v-if="type === 2">
          <template v-if="userInfo.signPwdStatus">
            <div class="header">
              <div class="left" @click="cancel">取消</div>
              <div class="mid">密码验证</div>
              <div class="right" @click.stop="changeWay(1)">
                <img src="@/assets/imgs/change.svg" class="change" alt="" />
                验证码验证
              </div>
            </div>
            <div class="content">
              <InputPWD
                @inputComplete="v => inputComplete(v, 1)"
                type="password"
                ref="inptpwd"
              />
            </div>
          </template>
          <template v-else>
            <div class="header">
              <div class="left" @click="cancel">取消</div>
              <div class="mid">设置签署密码</div>
              <div class="right" @click.stop="changeWay(1)">
                <img src="@/assets/imgs/change.svg" class="change" alt="" />
                验证码验证
              </div>
            </div>
            <div class="content">
              <div class="des">
                您尚未设置签署密码 请<span
                  @click="goUpdataPWD"
                  class="setPwdBtn"
                  >设置</span
                >或切换验证码验
              </div>
              <!-- <InputPWD
                @inputComplete="(v, code) => inputComplete(v, 3, code)"
                type="password"
                ref="inptpwd"
                :desType="2"
              /> -->
            </div>
          </template>
        </template>
        <template v-if="type === 1">
          <div class="header">
            <div class="left" @click="cancel">取消</div>
            <div class="mid">验证码验证</div>
            <div class="right" @click.stop="changeWay(2)">
              <img src="@/assets/imgs/change.svg" class="change" alt="" />
              密码验证
            </div>
          </div>
          <div class="content">
            <InputPWD
              @inputComplete="v => inputComplete(v, 2)"
              ref="inptpwd"
              :desType="1"
            />
          </div>
        </template>
      </template>
      <template v-if="signWay === 2">
        <template v-if="userInfo.signPwdStatus">
          <div class="header">
            <div class="left" @click="cancel">取消</div>
            <div class="mid">密码验证</div>
          </div>
          <div class="content">
            <InputPWD
              @inputComplete="v => inputComplete(v, 1)"
              type="password"
              ref="inptpwd"
            />
          </div>
        </template>
        <template v-else>
          <div class="header">
            <div class="left">取消</div>
            <div class="mid">设置签署密码</div>
          </div>
          <div class="content">
            <div class="des">
              您尚未设置签署密码 请先<span
                @click="goUpdataPWD"
                class="setPwdBtn"
                >设置</span
              >或切换验证码验证
            </div>
            <!-- <InputPWD
              @inputComplete="(v, code) => inputComplete(v, 3, code)"
              type="password"
              ref="inptpwd"
              :desType="2"
            /> -->
          </div>
        </template>
      </template>
      <!-- 验证码签署 -->
      <template v-if="signWay === 1">
        <div class="header">
          <div class="left" @click="cancel">取消</div>
          <div class="mid">验证码验证</div>
        </div>
        <div class="content">
          <InputPWD
            @inputComplete="v => inputComplete(v, 2)"
            ref="inptpwd"
            :des-type="1"
          />
        </div>
      </template>
      <!-- 验证码签署 -->
    </div>
  </van-popup>
</template>
<script>
  import Api from '@/api'
  import { mapState, mapGetters } from 'vuex'
  import InputPWD from '@/components/InputPWD.vue'
  import { Dialog, Toast } from 'vant'

  export default {
    props: ['signWay'], // 0.不指定 1.短信验证 2.密码验证
    components: {
      InputPWD,
    },
    data() {
      return {
        show: false,
        signPwdTimerFlag: false,
        inputValue: '',
        newSignPwd: '',
        code: '',
        type: 2,
        num: 60,
        signPwd: '',
        verifyCode: '',
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
        showSignPassword: state => state.contractSign.showSignPassword,
      }),
    },
    watch: {
      showSignPassword: {
        handler(newValue) {
          this.show = newValue
          if (this.signWay === 0 && !this.userInfo.signPwdStatus) {
            this.type = 1
          }
        },
        immediate: true,
      },
    },
    created() {
      if (!this.userInfo.signPwdStatus) {
        this.type = 1
      }
    },
    methods: {
      goUpdataPWD() {
        this.$router.push({
          path: '/updatePWD',
        })
      },
      cancel() {
        this.show = false
        this.$refs.inptpwd && this.$refs.inptpwd.reSetValue()
      },
      closed() {
        this.$store.commit('contractSign/setShowSignPassword', false)
        this.$refs.inptpwd && this.$refs.inptpwd.reSetValue()
      },
      inputComplete(v, type, code) {
        if (type === 1) {
          this.signPwd = v
          this.onSignPwd()
        }
        if (type === 2) {
          this.verifyCode = v
          this.onSignCode()
        }
        if (type === 3) {
          this.onUpdateSignPwd(v, code)
        }
      },
      changeWay(type) {
        this.type = type
        // this.$refs.inptpwd.reSetValue()
      },
      // 更换签约密码
      onUpdateSignPwd(newPwd, code) {
        this.toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        Api.updateSignPwd({
          code,
          newPwd,
        })
          .then(res => {
            this.$store.dispatch('fetchUserInfo')
            this.$refs.inptpwd.reSetValue()
          })
          .catch(err => {
            this.$store.commit('contractSign/setErrMsg', err.msg)
          })
          .finally(() => {
            this.toast.clear()
          })
      },
      // 验证码签署
      onSignCode() {
        this.$emit('confirm', {
          verifyType: 1,
          verifyCode: this.verifyCode,
        })
        // this.closed()
      },
      // 密码签署
      onSignPwd() {
        this.$emit('confirm', {
          verifyType: 2,
          signPwd: this.signPwd,
        })
        // this.closed()
      },
    },
  }
</script>
<style lang="less" scoped>
  .setPassWord {
    .header {
      width: 375px;
      height: 59px;
      border: 1px solid #f5f5f5;
      position: relative;
      padding: 0 16px;
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      background: #f4f5f6;
      .left {
        width: 36px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #677283;
        line-height: 18px;
        position: absolute;
        top: 20px;
        left: 16px;
      }
      .mid {
        width: 127px;
        height: 18px;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #111a34;
        line-height: 18px;
        letter-spacing: 0px;
        margin: 0 auto;
      }
      .right {
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: right;
        color: #677283;
        line-height: 18px;
        position: absolute;
        top: 20px;
        right: 16px;
        .change {
          width: 14px;
          height: 14px;
          margin-right: 4px;
          vertical-align: middle;
        }
      }
    }
    .content {
      margin-bottom: 53px;
      .des {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #677283;
        line-height: 20px;
        text-align: center;
        margin-bottom: 16px;
        .setPwdBtn {
          color: #1676ff;
        }
      }
      .value-list {
        margin: 0 auto;
        width: 295px;
        height: 50px;
        border: 1px solid #e2e4ea;
        border-radius: 2px;
        display: flex;
        margin-bottom: 24px;
        .value-item {
          width: 49px;
          height: 50px;
          border-right: 1px solid #e2e4ea;
          &:last-child {
            border-right: none;
          }
        }
      }
      .sendCode {
        margin: 0 auto;
        width: 295px;
        height: 50px;
      }
      /deep/.van-cell {
        padding: 13px 0;
        height: 48px;
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 24px;
      }
      .send-code {
        height: 18px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(22, 118, 255, 1);
        line-height: 18px;
      }
      .send-code2 {
        height: 18px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(109, 119, 144, 1);
        line-height: 18px;
      }
      .getCode {
        height: 12px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #1676ff;
        line-height: 12px;
      }
      .getCode2 {
        height: 12px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #8a9ab4;
        line-height: 12px;
      }
    }
  }
</style>
